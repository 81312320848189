import Modal from "../../components/Modal";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { Button } from "../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { ProviderIntroHeader } from "./components/ProviderDetailsHeader";
import { ImageView } from "../../components/Image";
import { getInitial } from "../../services/utils";
import { acceptedInsurances, focusAreas } from "../../constant";
import { zipCodes } from "../../assets/data/zip_codes";

const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])

function ProviderIntroMobileView({ provider, onSchedule, onClose }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setIsScrolled(e.target.scrollTop > 250);
    };
    const element = document.getElementById("provider-scroll");
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`p-4 bg-white rounded-2xl gap-3.5 flex lg:hidden flex-col`}
      >
        <div className="items-start md:items-center gap-4 pb-3.5 flex border-b border-[#DDDDDD]">
          {provider.profileImg ? (
            <ImageView
              className="w-20 h-20 rounded-2xl object-cover"
              src={provider.profileImg || "/images/profile1.png"}
              alt="provider"
              loading="lazy"
            />
          ) : (
            <div className="uppercase w-20 h-20 rounded-2xl flex justify-center items-center text-3xl text-primary bg-secondary">
              {getInitial(provider?.firstName, provider?.lastName)}
            </div>
          )}
          <div className="flex-1 justify-between gap-2 md:gap-1 items-start md:items-center flex flex-col md:flex-row">
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="flex-col justify-start items-start gap-1.5 flex">
                <div className="justify-start items-start gap-2.5 flex flex-col md:flex-row">
                  <div className="text-black text-lg font-semibold">
                    {provider.firstName} {provider.lastName}
                  </div>
                </div>
                <div className="text-black text-sm font-normal ">
                  {provider?.profession?.join(", ")}
                </div>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <LocationIcon className="w-[18px] h-[18px] justify-center items-center flex" />
                <div className="text-black text-sm font-normal ">
                  {provider?.addressState}
                </div>
              </div>
              {provider?.isAvailable && (
                <div className="pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-teal-500 border-opacity-25 justify-start items-center gap-0.5 flex">
                  <CheckIcon className="w-4 h-4" />
                  <div className="text-black text-sm font-normal ">
                    Accepting New Clients
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          variant="primary"
          className="py-[14px] px-6 !rounded-full"
          onClick={onSchedule}
          disabled={!provider?.isAvailable}
        >
          <CalendarIcon className="w-5 h-5 relative [&>path]:fill-white" />
          <div className="text-white text-sm font-medium">
            Schedule Consultation
          </div>
        </Button>
      </div>
      <div
        className={`fixed top-0 left-0 w-full pl-2.5 pr-4 py-2 bg-white shadow-[0px_4px_44px_0px_rgba(0,0,0,0.25)] justify-between items-center flex lg:hidden ${isScrolled
          ? "opacity-100 transition-[width_0.75s,height_0.75s,opacity_0.75s_0.75s]"
          : "opacity-0 transition-[width_0.75s_0.75s,height_0.75s_0.75s,opacity_0.75s]"
          }`}
      >
        <div className="justify-start items-center gap-2.5 flex">
          {provider.profileImg ? (
            <ImageView
              className="w-10 h-10 object-cover rounded-md"
              src={provider.profileImg}
              alt="user"
              loading="lazy"
            />
          ) : (
            <div
              className={`uppercase w-10 h-10 rounded-md flex justify-center items-center text-xl text-primary bg-secondary`}
            >
              {getInitial(provider?.firstName, provider?.lastName)}
            </div>
          )}
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="flex-col justify-start items-start gap-1.5 flex">
              <div className="text-black text-sm font-medium">
                {provider?.firstName} {provider?.lastName}
              </div>
            </div>
          </div>
        </div>
        <div className="justify-end items-center gap-2 flex">
          {provider?.isAvailable && (
            <Button
              variant="primary"
              className="py-[14px] px-6 !rounded-full"
              onClick={onSchedule}
            >
              <CalendarIcon className="w-5 h-5 relative [&>path]:fill-white" />
              <div className="text-white text-sm font-medium">Schedule</div>
            </Button>
          )}
          <button type="button" onClick={onClose}>
            <CloseIcon className="w-5 h-5 relative" />
          </button>
        </div>
      </div>
    </>
  );
}

export function ProviderDetails({ onClose, isOpen, provider, onSchedule, hideScheduleBtn = false }) {
  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider.addressZip]);
  const addressZip = provider?.addressZip

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-75px)] lg:max-h-[calc(100vh-36px)] overflow-auto cursor-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <>
            <div className="hidden lg:flex p-5 bg-white rounded-2xl gap-2 flex-col">
              <ProviderIntroHeader
                provider={provider}
                onClose={onClose}
                onSchedule={onSchedule}
              />
              <div className="items-start md:items-center gap-6 flex">
                {provider.profileImg ? (
                  <ImageView
                    className="w-32 h-32 rounded-2xl object-cover"
                    src={provider.profileImg || "/images/profile1.png"}
                    alt="provider"
                    loading="lazy"
                  />
                ) : (
                  <div
                    className={`uppercase w-32 h-32 rounded-2xl flex justify-center items-center text-[40px] text-primary bg-secondary`}
                  >
                    {getInitial(provider?.firstName, provider?.lastName)}
                  </div>
                )}
                <div className="flex-1 justify-between gap-2 md:gap-1 items-start md:items-center flex flex-col md:flex-row">
                  <div className="flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="flex-col justify-start items-start gap-2.5 flex">
                      <div className="justify-start items-start gap-2.5 flex flex-col md:flex-row">
                        <div className="text-black text-2xl font-semibold max-w-[190px]">
                          {provider.firstName} {provider.lastName}
                        </div>
                        {provider?.isAvailable && (
                          <div className="pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-teal-500 border-opacity-25 justify-start items-center gap-0.5 flex">
                            <CheckIcon className="w-4 h-4" />
                            <div className="text-black text-sm font-normal ">
                              Accepting New Clients
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-black text-base font-normal ">
                        {provider?.profession?.join(", ")}
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <LocationIcon className="w-6 h-6 justify-center items-center flex" />
                      <div className="text-black text-base font-normal ">
                        {`${provider?.distanceFromZip ? `${provider?.distanceFromZip} mi.` : ''} ${zipCity ? `${zipCity}, ${zipState}` : ''} ${addressZip ? ` (${addressZip})` : `${provider?.addressState}`}`}
                      </div>
                    </div>
                  </div>
                  {!hideScheduleBtn && (
                    <Button
                      variant="primary"
                      className="py-[14px] px-6 !rounded-full"
                      onClick={onSchedule}
                      disabled={!provider?.isAvailable}
                    >
                      <CalendarIcon className="w-5 h-5 relative [&>path]:fill-white" />
                      <div className="text-white text-sm font-medium">
                        Schedule Consultation
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <ProviderIntroMobileView
              provider={provider}
              onSchedule={onSchedule}
              onClose={onClose}
            />
          </>
          <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
            <div className="text-black text-base font-semibold">About Me</div>
            <p>{provider?.profileSummary}</p>
          </div>
          <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
            <div className="text-black text-base font-semibold">
              My Focus Areas
            </div>

            <ul className="list-disc marker:text-primary ml-5 mt-2">
              {provider?.focusAreas?.map((f, index) => (
                <li
                  key={index}
                  className="text-black text-base font-light mb-2"
                >
                  {focusAreas.find((area) => area.value === f)?.label || f}
                </li>
              ))}
            </ul>
          </div>
          <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
            <div className="text-black text-base font-semibold">
              Accepted Insurance
            </div>
            <div className="flex items-center flex-wrap gap-1.5">
              {provider?.acceptedInsurances?.map((practice, index) => (
                <div
                  key={index}
                  className="bg-secondary rounded-full px-5 py-2"
                >
                  <span className="text-black text-base font-light">
                    {acceptedInsurances.find(
                      (insurance) => insurance.value === practice
                    )?.label || practice}
                  </span>
                </div>
              ))}
              {provider?.acceptedInsurances?.length === 0 && (
                <div className="text-black text-base font-light">
                  Not Available
                </div>
              )}
            </div>
          </div>
          <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
            <div className="text-black text-base font-semibold">
              Where I Practice
            </div>
            <div className="flex items-center flex-wrap gap-1.5">
              {provider?.practiceAreas?.map((practice, index) => (
                <div className="bg-secondary rounded-full px-5 py-2">
                  <span className="text-black text-base font-light">
                    {practice}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
