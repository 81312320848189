import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import { Header } from "../components/Header";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { setToken, setUser as setUserInfo } from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { Password } from "../components/Password";
import passwordValidation from "../register/Step1";
import { FamilyRegistration } from "../register/FamilyRegistration";
import { WEBSITE_URL } from "../../../env";
import { ScheduleConsultModal } from "../../providers/ScheduleConsultModal";

export function FamilyLogin() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showScheduleConsultModal, setShowScheduleConsultModal] = React.useState<any>(false);
  const [showInsuranceUploadView, setShowInsuranceUploadView] = useState<
    number | boolean
  >(false);
  const [error, setError] = React.useState<string>("");
  const [errors, setErrors] = useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  console.log(state?.from)

  React.useEffect(() => {
    const signup = searchParams.get("signup")
    if (signup) {
      setShowScheduleConsultModal(true)
      setSearchParams(undefined)
    }
    const token = searchParams.get("token");
    const id = searchParams.get("id");
    if (token && id) {
      handleLoginSuccess({ accessToken: token, id, isEmailVerified: true });
    }
  }, [searchParams, setSearchParams])

  const handleLoginSuccess = async (result: { accessToken: string, id: string, isEmailVerified: boolean }) => {
    setToken(result?.accessToken);
    if (result?.id) {
      const userResponse = await api.getUserProfile(result?.id);
      setUserInfo({
        ...userResponse,
        isEmailVerified: result?.isEmailVerified,
      });
      if (userResponse?.type === "P") {
        if (state?.from) {
          navigate(state?.from);
          return;
        }
        navigate("/provider/bulletin")
        return
      }
      if (!result?.isEmailVerified) {
        await api.initEmailVerification(result?.id);
        setShowInsuranceUploadView(1);
        setLoading(false);
        return;
      }
      const insuranceResponse = await api.getInsurance(result?.id);
      if ([200, 201].includes(insuranceResponse.status)) {
        const hasInsuranceData = insuranceResponse?.data?.length > 0;
        if (!hasInsuranceData) {
          setShowInsuranceUploadView(2);
          setLoading(false);
          return;
        }
      }
      navigate("/family/dashboard");
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);

    if (data.email && (data.email.toString().toLowerCase().trim() === "admin@micro1.ai" || (data.email.toString().toLowerCase().trim().includes("@headstart.health") && !data.email.toString().toLowerCase().trim().includes("+")))) {
      setErrors((prev) => ({
        ...prev,
        email: "Hi Team! Please login in using the Headstart Admin Panel. This login is only for our clients/providers to use.",
      }));
      return;
    }


    // log the results
    setError("");
    setLoading(true);
    try {
      const response = await api.login({
        email: data.email,
        password: data.password,
        userType: "PF",
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.accessToken) {
        handleLoginSuccess(result)
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password: "Invalid password",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleGoogleLogin = async (response: any) => {
    const payload = {
      email: response.data.email,
      googleId: response.data.id,
      userType: "PF",
    };
    setError("");
    setLoading(true);
    try {
      const response = await api.login(payload);
      if (response.status === 401) {
        setError("Google id not found");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.accessToken) {
        setToken(result?.accessToken);
        if (result?.id) {
          const userResponse = await api.getUserProfile(result?.id);
          setUserInfo({ ...userResponse, isEmailVerified: true });
        }
        navigate("/family/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError(error?.response?.data?.message || "Something went wrong");
      return false;
    }
  };
  if (showInsuranceUploadView) {
    return (
      <FamilyRegistration defaultStep={showInsuranceUploadView as number} />
    );
  }




  if (showScheduleConsultModal) return <>
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignUp />
      <div className="py-10 px-4 md:px-0">
        <div className="flex justify-center items-center">
          <div className="max-w-[820px] lg:w-[63vw]"></div>
          <ScheduleConsultModal
            provider={undefined}
            onCancel={function (): void {
              setShowScheduleConsultModal(false)
            }}
            onConfirm={function (): void {
              setShowScheduleConsultModal(false)
            }} />
        </div>
      </div>
    </div >
  </>;

  return (
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignUp />
      <div className="py-10 px-4 md:px-0 tall2:h-[calc(100vh-80px)] md:flex md:items-center md:justify-center">
        <div className="h-full md:h-auto flex justify-center items-center">
          <div className="w-[442px]">
            <div className="bg-white p-8 rounded-[16px]">
              <h1 className="text-3xl font-bold">Sign In</h1>
              <form
                className="mt-4"
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                {error && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    <AlertIcon /> {error}
                  </div>
                )}
                <div className="mb-[22px] flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    Email
                  </label>
                  <TextInput
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                  />
                  {errors.email && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className=" flex flex-col gap-1.5">
                  <label htmlFor="password" className="text-sm text-black/80">
                    Password
                  </label>
                  <Password
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                  />
                  {errors.password && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-[14px]">
                  <a
                    href="/forgot-password"
                    className="text-black/80 text-sm font-medium hover:text-primary"
                  >
                    Forgot password?
                  </a>
                </div>
                <div className="mt-7">
                  <Button
                    type="submit"
                    variant="primary"
                    className="!rounded-full w-full"
                    disabled={disabled || loading}
                    loading={loading}
                  >
                    Login
                  </Button>
                </div>
              </form>
              {/* <div className="my-[15px] flex justify-center">
                <span className="text-black text-base">or</span>
              </div>
              <SocialLogin onGoogleLogin={handleGoogleLogin} /> */}
              <div className="flex items-center gap-1 justify-center mt-6 text-sm">
                <span className="text-black font-medium">
                  Don't have an account?
                </span>
                <a
                  href="/login?signup=true"
                  className="text-primary/80 font-bold  hover:text-black"
                >
                  Create Account
                </a>
              </div>
            </div>

            <div className="flex gap-2 bg-white p-8 rounded-[16px] mt-[14px]">
              <span className="flex-1">
                Are you a provider interested in starting your own practice?
              </span>
              <a href={`${WEBSITE_URL}/get-started`}>
                <Button type="button" variant="primary">
                  Contact Us
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
