/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ProviderList } from "./components/ProviderList";
import { SearchView } from "./components/SearchView";
import { useProvider } from "./context/provider-context";
import { FilterModal } from "./components/FilterModal";
import { ProviderDetails } from "./ProviderDetails";
import { getUser } from "../../services/utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useApp } from "../../services/app-context";
import { api } from "../../services/api";
import { AnimatePresence } from "framer-motion";
import { SelectedFilters } from "./components/SelectedFilters";
import { ShowToast } from "../../services/toast";
import { EmptyStates } from "../../components/EmptyStates";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as ThreeProCircles } from "../../assets/icons/threeProCircles.svg";

import { WEBSITE_URL } from "../../env";
import { PromptZipModal } from "./PromptZipModal";
import { ScheduleConsultModal } from "./ScheduleConsultModal";

const hasFilters = (filters) => {
  if (filters.showAvailable) return true;
  if (filters.providerTypes.length > 0) return true;
  if (filters.focusAreas.length > 0) return true;
  if (filters.addressState) return true;
  return false;
};


const getH1Content = (version) => {

  switch (version) {
    case 'aba-therapy-providers':
      return 'Browse Local ABA Therapy Providers';
    case 'autism-therapy-providers':
      return 'Browse Local Autism Therapy Providers';
    default:
      return 'Browse Providers';
  }
}

const getH2Content = (version) => {
  switch (version) {
    case 'aba-therapy-providers':
      return 'We specialize in ABA therapy and autism services. All practices are clinician owned and there is no waitlist - choose your local ABA therapist and get started today!';
    case 'autism-therapy-providers':
      return <span className="">
      We specialize in autism therapy. All practices are clinician owned and there is no waitlist - choose your local ABA therapist and get started today!
      </span>
    default:
      return <>
        We specialize in <span className="font-bold absolute mx-1">
          autism services.
          <span className="relative top-[-5px] left-[1px]">
            <svg width="139" height="7" viewBox="0 0 139 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 5C24.3333 3.83333 85.3 1.5 138.5 1.5" stroke="#00AE9B" stroke-width="2.5" />
            </svg>
          </span>
        </span>
        <span className="mr-[155px]" />
        Connect with a <span className="pl-[2px] sm:pl-0 font-bold absolute">
          BCBA
          <span className="relative top-[-5px] left-[-1px]">
            <svg width="55" height="6" viewBox="0 0 55 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4C10.3261 3.33333 34.1826 2 55 2" stroke="#00AE9B" stroke-width="2.5" />
            </svg>
          </span>
        </span>
        <span className="mr-[59px]" />
        offering high-quality
        <span className="font-bold absolute mx-1">
          ABA therapy
          <span className="relative top-[-5px] left-[-1px]">
            <svg width="119" height="6" viewBox="0 0 119 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M118 4C97.6208 3.33333 45.4899 2 3.45707e-06 2" stroke="#00AE9B" stroke-width="2.5" />
            </svg>
          </span>
        </span>
        <span className="mr-[128px]" />
        near you.
        </>
  }
}

export function Providers({ version }: { version?: 'aba-therapy-providers' | 'autism-therapy-providers' }) {
  const {
    providers,
    setPage,
    total,
    page,
    search,
    setSearch,
    isLoading,
    totalPages,
    filters,
    setFilters,
    searchByZip,
    setSearchByZip
  } = useProvider() as any;
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [provider, setProvider] = useState<any>(null);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const user = getUser();
  const navigate = useNavigate();
  const { setSelectedProvider } = useApp() as any;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSetZipModal, setShowSetZipModal] = useState(true);
  const [showScheduleConsultModal, setShowScheduleConsultModal] = useState<any>(false);

  const h1Content = getH1Content(version);
  const h2Content = getH2Content(version);

  const handlePageChange = (e) => {
    setPage(e.selected);

    var element = document.getElementById('resultsList');
    var headerOffset = 220;
    var elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const handleSchedule = async (provider) => {
    setSelectedProvider(provider)
    // check if user is logged in
    if (!user?.id) {
      // redirect to register page
      setShowScheduleConsultModal(provider)
    } else {
      // redirect to messaging page
      // create schedule appointment
      const payload = {
        providerId: provider.id,
        patientId: user?.id,
      };
      try {
        const response = await api.scheduleAppointment(payload);
        if ([200, 201].includes(response.status)) {
          navigate("/family/dashboard");
        }
      } catch (error: any) {
        if (
          error.response?.data?.message === "Already scheduled consultation"
        ) {
          navigate("/family/dashboard");
        } else if (
          error.response?.data?.message.includes("You have an existing provider chat")
        ) {
          navigate(`/family/dashboard?switch=true&desired-provider-id=${provider.id}`);
        } else {
          ShowToast({
            message:
              error.response.data.message ||
              "Unable to schedule appointment, please try again later",
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    const first = params?.providerFirst
    const last = params?.providerLast

    if (first && last) {
      setShowSetZipModal(false)
      setSearch('')
      navigate(`/providers?f=${first}&l=${last}`, { replace: true })
    }
  }, [navigate, params?.providerFirst, params?.providerLast])

  useEffect(() => {
    if (!providers) return;
    setTimeout(() => {
      const first = searchParams.get("f")
      const last = searchParams.get("l")
      if (first && last) {
        const desiredProvider = providers.filter(p => p?.firstName?.toLowerCase() === first.toLowerCase()
          && p?.lastName?.toLowerCase() === last.toLowerCase())[0];
        if (desiredProvider) {
          setShowSetZipModal(false)
          setProvider(desiredProvider)
        }
        setSearchParams(undefined)
      }
    }, 1000)
  }, [providers])

  useEffect(() => {
    setSearch('')
    setSearchByZip(true)
  }, [])

  return (
    <div className="relative">
      {isBannerOpen && (
        <div
          style={{
            backgroundImage: "url('/images/banner-overlay.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "0%",
            backgroundAttachment: "scroll",
          }}
          className="fixed top-0 z-[2] left-0 right-0 h-24 md:h-[58px] p-5 flex justify-center items-center w-full bg-primary"
        >
          <div className="absolute top-0 h-24 md:h-[58px]"></div>
          <span className="font-medium text-lg leading-[21.6px] tracking-[-0.035px] text-white">
            Headstart is now live in Indiana -- coming soon to other states! Out
            of state?{" "}
            <a className="underline" href={`${WEBSITE_URL}/join-our-waitlist`}>
              Join our waitlist.
            </a>
          </span>
          <button
            className="absolute right-[18px]"
            onClick={() => setIsBannerOpen(false)}
          >
            <CloseIcon className="[&_path]:stroke-white" />
          </button>
        </div>
      )}
      <div
        className="bg-tintPrimary relative pt-24 md:pt-16"
        style={{
          backgroundImage: "url('/images/header-bg-overlay.png')",
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
        }}
      >
        <Header user={user} isBannerOpen={isBannerOpen} showGetStartedAndQuestions={!user?.id} onClickGettingStarted={() => setShowScheduleConsultModal(true)} />
        <div
          className={`pb-[65px] ${isBannerOpen ? '' : 'mt-[15px] md:mt-[-45px] lg:mt-[-50px] '} flex flex-col items-center 
          ${!user?.id ? '' : ` ${isBannerOpen ? 'mt-[0px] md:mt-[-65px] lg:mt-[-50px]' : 'mt-[-90px] md:mt-[-85px] lg:mt-[-90px]'}`}
          `}
        >
          <div
            className={`${isBannerOpen ? "pt-24" : "pt-[50px]"} md:pt-[10.5rem] lg:pt-[12.5rem] text-center`}
          >
            <h1 className="leading-none text-3xl md:text-[60px] font-bold text-black max-w-[350px] sm:max-w-[600px] lg:max-w-[700px] mx-auto">
              {h1Content}
            </h1>


            <h2 className="mx-auto text-[20px] mt-[10px] md:mt-[30px] max-w-[350px] sm:max-w-[600px] lg:mb-[10px]">
              {h2Content}
            </h2>

          </div>
        </div>
        <SearchView
          search={search}
          searchByZip={searchByZip}
          setSearchByZip={newVal => {
            setSearchByZip(newVal);
            setSearch('');
            const searchInput = document.getElementById("searchInput") as HTMLInputElement | null;
            if (searchInput) {
              searchInput.value = '';
            }
          }}
          onFilter={() => setFilterModalOpen(true)}
          hasFilters={hasFilters(filters)}
          onSearch={(search) => {
            setPage(0);
            setSearch(search);
          }}
        />
      </div>

      {/** provider's list */}
      <div className="mx-2 lg:mx-auto max-w-[1240px] my-24">
        <SelectedFilters
          filters={filters}
          onClear={setFilters}
          total={total}
          isLoading={isLoading}
        />

        <>
          <div className="justify-items-center md:max-w-[750px] lg:max-w-[1240px] mx-2 md:mx-auto">
            <div className="py-5 pl-5 pr-5 bg-[#E4DDF4B2] rounded-3xl flex w-full lg:w-[1010px] mb-5">

              <div className="hidden md:block items-center my-auto">
                <ThreeProCircles />
              </div>

              <div className="no-flex ml-5 lg:ml-10">
                <p className="text-lg lg:text-2xl font-bold">
                  About Our Providers
                </p>

                <p className="text-sm lg:text-base block opacity-80">
                  Headstart is a leading autism service provider that focuses on providing high-quality, in-home ABA therapy. Browse our network of BCBAs and pick the one that best suits your needs. No more standardized care. Your child is one-of-one, and their therapy should be no different!
                </p>
              </div>

            </div>
          </div>
        </>

        {isLoading ? (
          <div className="flex flex-wrap gap-5">
            {/* {Array(10)
              .fill(0)
              .map((_, i) => (
                <SkeletonView key={i} />
              ))} */}
          </div>
        ) : (
          <>
            {providers?.length > 0 ? (
              <div id="resultsList">
                <ProviderList
                  providers={providers}
                  page={page + 1}
                  onPageChange={handlePageChange}
                  onView={(provider) => setProvider(provider)}
                  onSchedule={(provider) => {
                    handleSchedule(provider)
                  }}
                  totalPages={totalPages}
                />
              </div>
            ) : (
              <div className="flex my-16 md:my-32 justify-center items-center text-2xl font-bold text-black">
                <EmptyStates text={`${(searchByZip && search.length > 0) ? 'No providers found close to your location' : 'No providers found matching your specifications'}`} />
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      <AnimatePresence mode="wait" initial={false}>
        {filterModalOpen && (
          <FilterModal
            onClose={handleFilterModalClose}
            filters={filters}
            setFilters={(newFilters) => {
              setFilters(newFilters);
              setPage(0);
            }}
          />
        )}
      </AnimatePresence>
      {provider && (
        <ProviderDetails
          onClose={() => setProvider(null)}
          isOpen={!!provider}
          provider={provider}
          onSchedule={() => {
            setProvider(null)
            handleSchedule(provider)
          }}
        />
      )}
      {showSetZipModal &&
        <PromptZipModal
          onCancel={function (): void {
            setShowSetZipModal(false)
          }}
          onConfirm={function (zip: string): void {
            setSearch(zip)
            setSearchByZip(true)
            setShowSetZipModal(false)
          }} />
      }
      {showScheduleConsultModal &&
        <ScheduleConsultModal
          provider={showScheduleConsultModal}
          onCancel={function (): void {
            setShowScheduleConsultModal(false)
          }}
          onConfirm={function (): void {
            setShowScheduleConsultModal(false)
          }} />
      }
    </div>
  );
}
