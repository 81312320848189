import { useEffect, useMemo, useState } from "react";
import ReactGA from 'react-ga4';
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import { SocialLogin } from "../SocialLogin";
import { api } from "../../../services/api";
import { Password } from "../components/Password";
import { ShowToast } from "../../../services/toast";
import {
  getUser,
  setToken,
  setUser,
  validateEmail,
  validatePhone,
} from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { ReactComponent as FilledCheckboxIcon } from "../../../assets/icons/checkbox-filled.svg";

import { TextArea } from "../../../components/Textarea";
import { ConfirmEmail } from "./components/ConfirmEmail";
import { WEBSITE_URL } from "../../../env";
import { zipCodes } from "../../../assets/data/zip_codes";
import { SelectInput } from "../../../components/SelectInput";
import { HowDidYouHearAboutUs } from "../patient.types";
import { PartialSignUp } from "../../providers/partial-signup-patient.types";
import { useNavigate } from "react-router-dom";

export default function passwordValidation(password: string) {
  //   add special character
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*-_]{8,}$/;
  return passwordRegex.test(password);
}

export function FamilyRegistrationStep1(
  { onNext,
    onGoogleId,
    isFromLogin,
    partialSignUpInfo }
    : {
      onNext: () => void;
      onGoogleId: (googleId: string) => void;
      isFromLogin: boolean;
      partialSignUpInfo: PartialSignUp | undefined;
    }
) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({} as any);
  const [error, setError] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [userInfo, setUserInfo] = useState({} as any);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [form, setForm] = useState<
    {
      email: string;
      password: string;
      confirmPassword: string;
      howDidYouHearAboutUs?: HowDidYouHearAboutUs;
      howDidYouHearAboutUsText?: string;
      userType: string;
      googleId: string;
      firstName: string;
      lastName: string;
      guardianFirstName: string;
      guardianLastName: string;
      dob: string;
      zipCode: string;
      mobile: string;
      profileSummary: string;
    }
  >({
    email: "",
    password: "",
    confirmPassword: "",
    howDidYouHearAboutUs: undefined,
    howDidYouHearAboutUsText: "",
    userType: "PF",
    googleId: "",
    firstName: "",
    lastName: "",
    guardianFirstName: "",
    guardianLastName: "",
    dob: "",
    zipCode: "",
    mobile: "",
    profileSummary: "",
  });

  useEffect(() => {
    if (partialSignUpInfo) {
      setForm({
        ...form,
        ...partialSignUpInfo
      })
    }
  }, [partialSignUpInfo])


  const textReasonRequiredHeardAboutUs = form.howDidYouHearAboutUs && [
    HowDidYouHearAboutUs.ReferralFromAnotherCareProvider,
    HowDidYouHearAboutUs.SchoolOrDaycare,
    HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter,
    HowDidYouHearAboutUs.Other].includes(form.howDidYouHearAboutUs)

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "zipCode") {
      if (isZipValid(e.target.value)) {
        setErrors({ ...errors, zipCode: undefined })
      }
      setForm({ ...form, zipCode: e.target.value })
    }

    if (name === "mobile") {
      // allow only number
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value && !validatePhone(value.toString())) {
          setErrors((prev) => ({
            ...prev,
            mobile: "Invalid mobile number",
          }));
        } else {
          setErrors((prev) => ({ ...prev, mobile: "" }));
        }
        setForm((prev) => ({ ...prev, [name]: value }));
        return;
      } else {
        return;
      }
    }
    if (name === "password") {
      if (!passwordValidation(value)) {
        setErrors((prev) => ({
          ...prev,
          password:
            "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
        }));
      } else {
        setErrors((prev) => ({ ...prev, password: "" }));
      }
    }
    if (name === "email") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }
    if (name === "confirmPassword") {
      if (value !== form.password) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "Password do not match",
        }));
      } else {
        setErrors((prev) => ({ ...prev, confirmPassword: "" }));
      }
    }

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};
    if (!form.zipCode || !isZipValid(form.zipCode)) {
      newErrors = { ...newErrors, 'zipCode': "Please provide client's valid five digit zip code" };
    }

    if (!form.howDidYouHearAboutUs) {
      newErrors = { ...newErrors, 'howDidYouHearAboutUs': "Please select how you heard about Headstart" };
    }

    if (!form.howDidYouHearAboutUsText && textReasonRequiredHeardAboutUs) {
      newErrors = { ...newErrors, 'howDidYouHearAboutUsText': `${form.howDidYouHearAboutUs} - Please explain` };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return
    }

    const payload = {
      email: form.email,
      password: form.password,
      howDidYouHearAboutUs: form.howDidYouHearAboutUs,
      howDidYouHearAboutUsText: form.howDidYouHearAboutUsText,
      userType: form.userType,
      googleId: form.googleId,
      firstName: form.firstName,
      lastName: form.lastName,
      guardianFirstName: form.guardianFirstName,
      guardianLastName: form.guardianLastName,
      dob: form.dob,
      zipCode: form.zipCode,
      mobile: form.mobile,
      profileSummary: form.profileSummary,
    };
    try {
      setErrors({});
      setError("");
      setSaving(true);
      const response = await api.signupFamily(payload);
      setSaving(false);
      const result = response;
      if (result?.accessToken) {
        ShowToast({
          type: "success",
          message: "You've successfully created a Headstart account.",
        });
        if (process.env.REACT_APP_ENV === "prod") {
          ReactGA.event("client-sign-up-completed-step1")
        }
        setToken(result?.accessToken);
        if (result?.id) {
          setSaving(true);
          const userResponse = await api.getUserProfile(result?.id);
          setSaving(false);
          setUser({ ...userResponse });
          setUserInfo({ ...userResponse });
        }
        if (!form.googleId && result?.id) {
          setSaving(true);
          await api.initEmailVerification(result?.id);
          setSaving(false);
          setShowEmailVerification(true);
        } else {
          onNext();
        }
      }
    } catch (error: any) {
      setSaving(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError(error?.response?.data?.message || "Something went wrong");
      return false;
    }
  };

  const handleGoogleLogin = async (response: any) => {
    const { data } = response;
    try {
      const validateResponse = api.validateEmail(data?.email);
      if ([200, 201].includes((await validateResponse).status)) {
        onGoogleId(data.id);
        setForm((prev) => ({
          ...prev,
          email: data.email,
          googleId: data.id,
          guardianFirstName: data.given_name,
          guardianLastName: data.family_name,
        }));
      }
    } catch (error: any) {
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const disabled = useMemo(() => {
    let flag =
      (!partialSignUpInfo && !form.email) ||
      (!partialSignUpInfo && !validateEmail(form.email)) ||
      !form.firstName ||
      !form.lastName ||
      (!partialSignUpInfo && !form.guardianFirstName) ||
      (!partialSignUpInfo && !form.guardianLastName) ||
      !form.dob ||
      (!partialSignUpInfo && !form.mobile) ||
      !form.zipCode ||
      !validatePhone(form.mobile) ||
      !form.howDidYouHearAboutUs

    // either googleId or password should be present
    if (!form.googleId) {
      flag = flag || !form.password;
      if (flag === false) {
        flag = flag || !passwordValidation(form.password);
      }
      // check if password and confirm password are same
      flag = flag || form.password !== form.confirmPassword;
    }
    return flag;
  }, [form]);

  const minDate = useMemo(() => {
    // 100 years back
    const date = new Date();
    date.setFullYear(date.getFullYear() - 100);
    return date.toISOString().split("T")[0];
  }, []);

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(form.zipCode) ? [zipCodes[form.zipCode]?.city, zipCodes[form.zipCode]?.state_short] : [undefined, undefined];
  }, [form.zipCode]);

  useEffect(() => {
    if (isFromLogin) {
      setShowEmailVerification(true);
      setUserInfo(getUser());
    }
  }, [isFromLogin]);

  if (showEmailVerification) {
    return (
      <div className="bg-white p-8 rounded-[16px] flex justify-center">
        <ConfirmEmail
          email={userInfo.email}
          userId={userInfo.id}
          onSuccess={onNext}
        />
      </div>
    );
  }


  return (
    <>
      <div className="bg-white p-8 rounded-[16px]">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Personal Information</h1>
          <div className="text-sm text-black/60">1/3</div>
        </div>
        <form className="mt-4 flex flex-col gap-[22px]" onSubmit={handleSubmit}>
          {!partialSignUpInfo && (
            <div className="flex flex-col md:flex-row items-center gap-3">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">
                  Parent / Guardian First Name
                </label>
                <TextInput
                  name="guardianFirstName"
                  value={form.guardianFirstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  maxLength={128}
                />
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Parent / Guardian Last Name
                </label>
                <TextInput
                  name="guardianLastName"
                  value={form.guardianLastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  maxLength={128}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Client First Name
              </label>
              <TextInput
                name="firstName"
                value={form.firstName}
                onChange={handleChange}
                placeholder="Enter first name"
                maxLength={128}
              />
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="password" className="text-sm text-black/80">
                Client Last Name
              </label>
              <TextInput
                name="lastName"
                value={form.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
                maxLength={128}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Client Date of Birth
              </label>
              <TextInput
                type="date"
                placeholder="mm/dd/yyyy"
                name="dob"
                value={form.dob}
                onChange={handleChange}
                max={new Date().toISOString().split("T")[0]}
                min={minDate} // last 100 years only
                className={`${!form.dob ? "!text-[#8D8E92]" : ""} ${(zipCity || errors.zipCode) ? 'mb-6' : ''}`}
              />
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="password" className="text-sm text-black/80">
                Client Zip Code
              </label>
              <TextInput
                name="zipCode"
                value={form.zipCode}
                onChange={handleChange}
                placeholder="Enter Zip Code"
              />
              {(zipCity && zipState) && <div className="text-xs">Client City: {zipCity}, {zipState}</div>}
              {errors.zipCode && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.zipCode}
                </div>
              )}
            </div>
          </div>

          <div className="flex-1 w-full flex flex-col gap-1.5">
            <label htmlFor="aboutMe" className="text-sm text-black/80">
              About me (Optional)
            </label>
            <TextArea
              name="profileSummary"
              value={form.profileSummary}
              onChange={handleChange}
              placeholder="Tell us about how Headstart can help?"
              maxLength={512}
            />
          </div>
          {!partialSignUpInfo && (<>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Phone Number
              </label>
              <TextInput
                type="tel"
                placeholder="Enter your phone number"
                value={form.mobile}
                onChange={handleChange}
                name="mobile"
                maxLength={10}
              />
              {errors.mobile && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.mobile}
                </div>
              )}
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Email
              </label>
              <TextInput
                type="email"
                placeholder="Enter your email address"
                value={form.email}
                onChange={handleChange}
                name="email"
                maxLength={128}
              />
              {errors.email && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.email}
                </div>
              )}
            </div>
          </>)}
          {!form.googleId && (
            <>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Password
                </label>
                <Password
                  type="password"
                  placeholder="Create a password"
                  value={form.password}
                  onChange={handleChange}
                  name="password"
                // disabled={!!form.googleId}
                />
                {errors.password && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="password" className="text-sm text-black/80">
                  Confirm Password
                </label>
                <Password
                  type="password"
                  placeholder="Re-enter the password"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                // disabled={!!form.googleId}
                />
                {errors.confirmPassword && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
            </>
          )}

          {!(partialSignUpInfo?.howDidYouHearAboutUs && (!textReasonRequiredHeardAboutUs || partialSignUpInfo?.howDidYouHearAboutUsText)) && 
          <>
          <div>
            <label className="text-sm text-black/80 mb-[-15px]">
              How did you hear about Headstart?
            </label>
            <SelectInput
              data={Object.values(HowDidYouHearAboutUs).map((item) => ({
                label: item,
                value: item,
              }))}
              name="type"
              value={form.howDidYouHearAboutUs}
              disabledOptionText={"Select how you heard about Headstart"}
              onChange={(e) => {
                setForm({ ...form, howDidYouHearAboutUs: e.target.value as HowDidYouHearAboutUs });
              }}
              className={`mt-0 ${!form.howDidYouHearAboutUs && "text-slate-400"}`}
            />
            {errors.howDidYouHearAboutUs && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                {errors.howDidYouHearAboutUs}
              </div>
            )}
          </div>

          {textReasonRequiredHeardAboutUs && (
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.ReferralFromAnotherCareProvider
                  && "What is the name of the institution or provider that referred you? (e.g. Happy Family Pediatrics, or Dr. John Smith)"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.SchoolOrDaycare
                  && "What is the name of the facility that referred you? (e.g. Sunny Horizons Daycare)"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter
                  && "What is the name of the group or resource center?"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.Other
                  && "Other (please specify):"}
              </label>
              <TextInput
                type="text"
                placeholder=""
                value={form.howDidYouHearAboutUsText}
                onChange={handleChange}
                name="howDidYouHearAboutUsText"
                maxLength={128}
              />
              {errors.howDidYouHearAboutUsText && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.howDidYouHearAboutUsText}
                </div>
              )}
            </div>
          )}
          </>}

          <div className="mt-7">
            {error && (
              <div className="text-red-500 text-xs font-medium mb-4 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <Button
              type="submit"
              variant="primary"
              className="!rounded-full w-full"
              disabled={disabled || saving}
              loading={saving}
            >
              Next
            </Button>
          </div>
        </form>
        {/* {!form.googleId && (
          <>
            <div className="my-[15px] flex justify-center">
              <span className="text-black text-base">or</span>
            </div>
            <SocialLogin onGoogleLogin={handleGoogleLogin} />
          </>
        )} */}
      </div>

      <div className="flex items-center gap-1 justify-center mt-10 text-sm">
        <span className="text-black font-medium">Already have an account?</span>
        <a href="/login" className="text-primary/80 font-bold hover:text-black">
          Sign In
        </a>
      </div>
    </>
  );
}
