import { PopupModal } from "../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ReactComponent as FilledCheckboxIcon } from "../../assets/icons/checkbox-filled.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/Alert.svg";
import ReactGA from 'react-ga4';
import { useMemo, useState } from "react";
import { zipCodes } from "../../assets/data/zip_codes";
import { getInitial, validateEmail, validatePhone } from "../../services/utils";
import { ImageView } from "../../components/Image";
import { ProviderIntroHeader } from "./components/ProviderDetailsHeader";
import { TextInput } from "../../components/TextInput";
import { WEBSITE_URL } from "../../env";
import { Button } from "../../components/Button";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { HowDidYouHearAboutUs } from "../auth/patient.types";
import { SelectInput } from "../../components/SelectInput";

export function ScheduleConsultModal({
  provider,
  onCancel,
  onConfirm,
}: {
  provider: any;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const [error, setError] = useState<string>("");
  const [errors, setErrors] = useState({} as any);
  const [saving, setSaving] = useState(false);
  const [showProviderInfoSection, setShowProviderInfoSection] = useState(true);
  const [form, setForm] = useState<{
    providerId?: string;
    guardianFirstName: string;
    guardianLastName: string;
    mobile: string;
    email: string;
    howDidYouHearAboutUs?: HowDidYouHearAboutUs;
    howDidYouHearAboutUsText?: string;
    terms: boolean;
  }>({
    providerId: provider?.id ?? undefined,
    guardianFirstName: "",
    guardianLastName: "",
    mobile: "",
    email: "",
    howDidYouHearAboutUs: undefined,
    howDidYouHearAboutUsText: "",
    terms: false,
  });
  const navigate = useNavigate();

  const textReasonRequiredHeardAboutUs = form.howDidYouHearAboutUs && [
      HowDidYouHearAboutUs.ReferralFromAnotherCareProvider,
      HowDidYouHearAboutUs.SchoolOrDaycare,
      HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter,
      HowDidYouHearAboutUs.Other].includes(form.howDidYouHearAboutUs)

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      // allow only number
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value && !validatePhone(value.toString())) {
          setErrors((prev) => ({
            ...prev,
            mobile: "Invalid mobile number",
          }));
        } else {
          setErrors((prev) => ({ ...prev, mobile: "" }));
        }
        setForm((prev) => ({ ...prev, [name]: value }));
        return;
      } else {
        return;
      }
    }

    if (name === "email") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const result = await api.createPartialClientSignup({
        providerId: form.providerId,
        guardianFirstName: form.guardianFirstName,
        guardianLastName: form.guardianLastName,
        email: form.email,
        mobile: form.mobile,
        howDidYouHearAboutUs: form.howDidYouHearAboutUs,
        howDidYouHearAboutUsText: form.howDidYouHearAboutUsText,
      })

      if (result.data.id) {
        if (process.env.REACT_APP_ENV === "prod") {
          ReactGA.event("client-completed-initial-partial-sign-up")
        }
        navigate(`/register/${result.data.id}`)
      }
    } catch (err: any) {
      setError(err?.response?.data?.message || "Something went wrong")
    }
  }

  const disabled = !form.terms || !form.guardianFirstName.trim() || !form.guardianLastName.trim()
    || !form.email || !form.mobile || !validateEmail(form.email) || !validatePhone(form.mobile) || !form.howDidYouHearAboutUs || (textReasonRequiredHeardAboutUs && !form.howDidYouHearAboutUsText)

  return (
    <PopupModal
      shouldStopPropagation={false}
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white 
      rounded-2xl flex-col justify-start items-center gap-6 flex w-full w-[90%] md:mx-auto md:w-[700px] max-w-[700px]">

      <div className="flex flex-col w-full">
        <div className="justify-end items-center gap-3 flex">
          <button onClick={onCancel} style={{ transform: "translate(15px,-15px)" }}>
            <CloseIcon className="w-5 h-5 opacity-80" />
          </button>
        </div>
        <p className="text-center text-4xl text-black font-bold mt-[-20px] mb-3">
          Get Started
        </p>

        {showProviderInfoSection && <>
          <div className="max-w-[350px] mx-auto border-2 border-primary rounded-lg">
            <div className="col-span-1 flex justify-center">
              <div className="flex items-center mt-2 mb-[-2px] text-sm text-left font-semibold">
                {provider?.firstName ? 'Your Provider' : 'You have not selected a provider'}
              </div>
            </div>
            <ProviderInfoSection provider={provider} onCancel={onCancel} onConfirm={onConfirm} navigate={navigate} hideSection={() => setShowProviderInfoSection(false)} />
          </div>
        </>}

        <form className="mt-4 flex flex-col gap-[22px]" onSubmit={handleSubmit}>

          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label className="text-sm text-black/80">
                Parent / Guardian First Name
              </label>
              <TextInput
                name="guardianFirstName"
                value={form.guardianFirstName}
                onChange={handleChange}
                placeholder="Enter your first name"
                maxLength={128}
              />
            </div>
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="password" className="text-sm text-black/80">
                Parent / Guardian Last Name
              </label>
              <TextInput
                name="guardianLastName"
                value={form.guardianLastName}
                onChange={handleChange}
                placeholder="Enter your last name"
                maxLength={128}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3 mt-[-10px]">
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Email
              </label>
              <TextInput
                type="email"
                placeholder="Enter your email address"
                value={form.email}
                onChange={handleChange}
                name="email"
                maxLength={128}
              />
              {errors.email && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.email}
                </div>
              )}
            </div>

            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Phone Number
              </label>
              <TextInput
                type="tel"
                placeholder="Enter your phone number"
                value={form.mobile}
                onChange={handleChange}
                name="mobile"
                maxLength={10}
              />
              {errors.mobile && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.mobile}
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="text-sm text-black/80 mb-[-15px]">
              How did you hear about Headstart?
            </label>
            <SelectInput
              data={Object.values(HowDidYouHearAboutUs).map((item) => ({
                label: item,
                value: item,
              }))}
              name="type"
              value={form.howDidYouHearAboutUs}
              disabledOptionText={"Select how you heard about Headstart"}
              onChange={(e) => {
                setForm({ ...form, howDidYouHearAboutUs: e.target.value as HowDidYouHearAboutUs });
              }}
              className={`mt-0 ${!form.howDidYouHearAboutUs && "text-slate-400"}`}
            />
            {errors.howDidYouHearAboutUs && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                {errors.howDidYouHearAboutUs}
              </div>
            )}
          </div>

          {textReasonRequiredHeardAboutUs && (
            <div className="flex-1 w-full flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.ReferralFromAnotherCareProvider
                  && "What is the name of the institution or provider that referred you? (e.g. Happy Family Pediatrics, or Dr. John Smith)"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.SchoolOrDaycare
                  && "What is the name of the facility that referred you? (e.g. Sunny Horizons Daycare)"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter
                  && "What is the name of the group or resource center?"}
                {form.howDidYouHearAboutUs === HowDidYouHearAboutUs.Other
                  && "Other (please specify):"}
              </label>
              <TextInput
                type="text"
                placeholder=""
                value={form.howDidYouHearAboutUsText}
                onChange={handleChange}
                name="howDidYouHearAboutUsText"
                maxLength={128}
              />
              {errors.howDidYouHearAboutUsText && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  {errors.howDidYouHearAboutUsText}
                </div>
              )}
            </div>
          )}


          <div className="flex items-center gap-1.5 mt-3">
            <div className="flex">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                checked={form.terms}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    terms: e.target.checked,
                  }))
                }
                className={`w-[22px] h-[22px] cursor-pointer ${form.terms ? "opacity-[0] !h-0 !w-0" : ""
                  }`}
              />
              {form.terms ? (
                <button
                  onClick={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      terms: false,
                    }))
                  }
                >
                  <FilledCheckboxIcon className="w-[22px] h-[22px]" />
                </button>
              ) : null}
            </div>
            <label htmlFor="terms" className="text-base text-[#1D1E1B]">
              I consent to Headstart's{" "}
              <a
                href={`${WEBSITE_URL}/privacy-policy`}
                className="text-primary font-medium hover:text-black"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </div>

          <div className="mt-3">
            {error && (
              <div className="text-red-500 text-xs font-medium mb-4 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <Button
              type="submit"
              variant="primary"
              className="!rounded-full w-full text-xl"
              disabled={disabled || saving}
              loading={saving}
            >
              Get Started {provider?.firstName && ` with ${provider.firstName}`}
            </Button>
          </div>
        </form>


      </div>
    </PopupModal >
  );
}


const ProviderInfoSection = ({ provider, onCancel, onConfirm, navigate, hideSection }) => {

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    if (!provider) return [undefined, undefined];
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider]);
  const addressZip = provider?.addressZip


  return <div className="flex p-2 bg-white rounded-2xl gap-2 flex-col min-w-[230px]">
    <div className="items-start md:items-center gap-4 flex">

      {provider?.firstName ?
        <>
          {provider?.profileImg ? (
            <ImageView
              className="w-16 h-16 rounded-2xl object-cover"
              src={provider?.profileImg || "/images/profile1.png"}
              alt="provider"
              loading="lazy"
            />
          ) : (
            <div
              className={`uppercase w-16 h-16 rounded-2xl flex justify-center items-center text-[40px] text-primary bg-secondary`}
            >
              {getInitial(provider?.firstName, provider?.lastName)}
            </div>
          )}
          <div className="flex-1 justify-between items-start md:items-center flex flex-col md:flex-row">
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="flex-col justify-start items-start gap-0.5 flex">
                <div className="justify-start items-start flex flex-col md:flex-row">
                  <div className="text-black text-base font-semibold">
                    {provider?.firstName} {provider?.lastName}
                  </div>
                </div>
                <div className="text-black font-normal text-sm">
                  {provider?.profession?.join(", ")}
                </div>
              </div>
              <div className="justify-start items-start gap-1.5 inline-flex">
                <LocationIcon className="w-4 h-4 mt-[2px] justify-center items-center flex" />
                <div className="text-black text-sm font-normal ">
                  {`${provider?.distanceFromZip ? `${provider?.distanceFromZip} mi.` : ''} ${zipCity ? `${zipCity}, ${zipState}` : ''} ${addressZip ? ` (${addressZip})` : `${provider?.addressState}`}`}
                </div>
              </div>
            </div>

          </div>
        </>

        :
        <div className="w-full">

          <Button
            variant="primary"
            className="w-full h-6 !rounded-full bg-primary mb-2"
            onClick={() => {
              navigate("/providers")
              onCancel()
            }}
          >
            Browse & Select a Provider
          </Button>
          <Button
            variant="primary"
            className="w-full h-6 !rounded-full bg-primary"
            onClick={hideSection}
          >
            I'll Select a Provider Later
          </Button>
        </div>
      }


    </div>
  </div>
}